body {
  font-family: 'Avenir-Light' sans-serif;
}
/*homepage styles*/
#menu {
  display: grid;
  grid-template-columns: [first] 20% [second] 20% [third] 20% [fourth] 20% [end];
  grid-column-start: third;
  margin: 0;
  padding: 0;
}

nav {
  display: grid;
  grid-template-columns: [first] 33% [second] 26% [third] 40% [end];
  position: sticky;
  top: 0px;
  background-color: white;
  padding: 10px 10px 10px 25px;
  
  
}


.nav a:hover {
  color: #4eacd4;
}

.nav a:active {
  color: slategrey;
}

.nav a {
  text-decoration: none;
  color: slategrey;
  font-size: 23px;
  
}

.nav li {
  margin-top: 30px;
  text-align: center;
  list-style-type: none;
}

#menu-icon {
  display: none;
  cursor: pointer;
}

/* Side menu styles*/

#side-menu {
  height: 100vh;
  width: 300px;
  position: absolute;
  top: 0;
  right: 0;
  background-color: white;
  box-shadow: 2px 8px 23px 3px rgba(0,0,0,.2);
  
}

.nav > li > a > u {
  text-decoration: none;
  border-bottom: 3px solid;
}

#close {
  width: 10px;
  height: 10px;
  fill: grey;
  margin-left: 5px;
  margin-top: 10px;
}

@keyframes menuOpen {
  from {right: -300px;}
  to {right: 0px;}
}

@keyframes menuClose {
  from {right: 0px;}
  to {right:-300px;}
}
/* End side menu styles */
#main-logo {
  width: 200px;
}



#home {
  height: 700px;
  display: grid;
  grid-template-columns: [first] 33% [second] 10% [third] 37% [fourth] 20% [end];
  grid-template-rows: [first] 20% [second] 20% [third] 20% [fourth] 20% [fifth] 20% [end];
  
}

#jordan-div {
  
  height: 100%;
  grid-column-start: first;
  grid-row-start: first;
  grid-row-end: end;
  
}

#jordan {
  height: 100%;
  border-right: white;
  border-right-style: solid;
  border-width: 5px;
}

#headers {
  
  display: grid;
  grid-template-columns: [first] 33% [second] 33% [third] 34% [end];
  grid-template-rows: [first] 33% [second] 16% [third] 16% [fourth] 33% [end];
  grid-column-start: third;
  grid-column-end: end;
  grid-row-start: second;
  grid-row-end: end;
}


h1 {
  grid-column-start: first;
  grid-column-end: end;
  display: block;
  color: white;
  font-size: 100px;
  font-family: 'Avenir-Light' sans-serif;
}

.chiropractic {
  display: block;
  color: white;
  grid-column-start: first;
  grid-column-end: end;
  grid-row-start: second;
  font-size: 50px;
  margin: 0;
}

#jordan-quote {
  color: white;
  grid-column-start: first;
  grid-column-end: end;
  grid-row-start: third;
  grid-row-end: third;
  font-weight: 400;
  font-size: 25px;
}



h3 {
  margin-right: auto;
  margin-left: auto;
  display: block;
  font-size: 30px;
  text-align: center;
  
}

#title {
  text-decoration: none;
  color: slategrey;
  font-size: 23px;
  margin-left: 20px;
  font-weight: bold;
}

#chiro {
  grid-column-start: first;
  text-decoration: none;
  color: slategrey;
  font-size: 23px;
  margin-left: 20px;
}

/*Services section of homepage*/
#treatment-spiel {
  grid-column-start: first;
  grid-column-end: end;
  margin-left: 200px;
  margin-right: 200px;
}
h3 {
  grid-column-start: second;
}
#services-homepage {
  color: grey;
  display: grid;
  grid-template-columns: [first] 33.33% [second] 33.33% [third] 33.33% [end];
  grid-template-rows: [first] 33.33% [second] 33.33% [third] 33.33% [end];
  height: 600px;
  /* grid-column-start: first; */
  /* grid-column-end: end; */
  /* grid-row-start: second; */
  /* grid-row-end: end; */

}

#chiropractic {
  
  grid-column-start: first;
  grid-column-end: first;
  grid-row-start: second;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
}
#chiropractic-svg {
  display: block;
  margin-right: auto;
  margin-left: auto;
  height: 100px;
  fill: grey;
  margin-top: 20px;
}

#e-stim {
  grid-column-start: second;
  grid-column-end: second;
  grid-row-start: second;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
}
#estim-svg {
  display: block;
  margin-right: auto;
  margin-left: auto;
  height: 100px;
  fill: grey;
  margin-top: 20px;
}
#cupping {
  grid-column-start: third;
  grid-column-end: third;
  grid-row-start: second;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
}
#cupping-svg {
  display: block;
  margin-right: auto;
  margin-left: auto;
  height: 100px;
  fill: grey;
  margin-top: 20px;
}



/* About Page */
#about-content-div {
  display: grid;
  grid-template-columns: [first] 1fr [second] minmax(400px, 1fr) [end];
  grid-template-rows: [first] 15% [second] 1fr [third] 35% [end];
  margin-bottom: 50px;
}

h4 {
  grid-column-start: first;
  grid-column-end: end;
  text-align: center;
}

#biography {
  color: grey;
  margin-left: 45px;
  margin-top: 45px;
  font-size: 35px;

}

#about-dr-jordan {
  display: none;
}
#intro {
  color: grey;
  grid-column-start: first;
  grid-column-end: second;
  grid-row-start: second;
  margin-right: 45px;
  margin-left: 45px;
  margin-top: 45px;
  line-height: 25px;
  padding-bottom: 50px;
}

#chiro-care-quote {
  grid-row-start: third;
  grid-row-end: third;
  grid-column-start: first;
  color: #4eacd4;
  font-size: 35px;
  margin-right: 25px;
  margin-left: 25px;
  margin-bottom: 25px;

}

#portrait-2 {
  grid-column-start: second;
  grid-row-start: second;
  grid-row-end: end;
  width: 95%;
  max-width: 500px;
  /* margin-top: 50px; */
  /* margin-right: 30px; */
  /* margin-bottom: 30px; */
}

#video-section {
  display: grid;
  grid-template-columns: [first] 1fr [second] 2fr [third] 1fr [end];
  grid-template-rows: [first] 10% [second] 90% [end];
}
#video-div {
  /* width: 500px;
  height: 500px; */
  overflow: hidden;
  grid-row-start: second;
  grid-column-start: second;
}
#video-title {
  color: grey;
  font-size: 20px;
  grid-row-start: first;
  grid-column-start: second;
}
#anecdote {
  display: block;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  
}


/* Services Page */
h4 {
  font-size: 40px;
  color: grey;
  text-align: center;
}
h5 {
  margin: 0;
  font-size: 25px;
  grid-column-start: second;
  grid-column-end: end;
  grid-row-start: second;
  /* text-align: center; */
  color: grey;
  height: 50px;
  margin-bottom: 30px;
  margin-left: 20px;
}
#chiropractic-services {
  display: grid;
  grid-template-columns: [first] minmax(600px, 1fr) [second] 1fr [end];
  grid-template-rows: [first] 10% [second] 6% [third] 1fr [end];
  height: 500px;
  padding-right: 110px;
  /* grid-template-rows: [first][second][end]; */
}

#adjustment-img {
  /* height: 500px; */
  margin-left: 10px;
  width: 90%;
  grid-column-start: first;
  grid-column-end: first;
  grid-row-start: first;
}


#chiropractic-text {
  grid-row-start: third;
  grid-column-start: second;
  color: grey;
  font-size: 19px;
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 25px;
}

#estim-img {
  display: block;
  width: 700px;
  margin-right: auto;
  margin-left: auto;
}

h6 {
  font-size: 25px;
  color: grey;
  margin-left: 300px;
  margin-bottom: 10px;
}

#estim-text {
  color: grey;
  font-size: 19px;
  margin-right: 300px;
  margin-left: 300px;
  margin-bottom: 60px;
}

#cupping-services {
  display: grid;
  grid-template-columns: [first] 1fr [second] minmax(600px, 1fr) [end];
  grid-template-rows: [first] 10% [second] 6% [third] 1fr [end];
  margin-top: 250px;
  /* height: 500px; */
  /* padding-right: 110px; */
}

h7 {
  font-size: 25px;
  font-weight: 600;
  color: grey;
  grid-column-start: first;
  grid-row-start: second;
  margin-left: 70px;

}

#cupping-img {
  width: 90%;
  grid-column-start: second;
  grid-row-start: first;
  margin-right: 25px;
  
}

#cupping-text {
  color: grey;
  font-size: 19px;
  grid-column-start: first;
  grid-row-start: third;
  margin-left: 70px;
  margin-top: 30px;
  margin-right: 30px;
}




/* Contact Page */

#contact-div > h2 {
  color: grey;
  text-align: center;
  font-weight: 600;
  font-size: 40px;
  grid-column-start: first;
  grid-column-end: end;
}

#contact-div {
  display: grid;
  grid-template-columns: [first] 50% [second] 25% [third] 25% [end];
  
  
}

.form {
  display: grid;
  grid-template-columns: [first] 1fr [second] 1fr [end];
  grid-column-start: second;
  grid-column-end: end;
  grid-template-rows: [first] 15% [second] 15% [third] 15% [fourth] 35% [fifth] 20% [end];
  color: grey;
  font-size: 20px;
  font-weight: 400;
}

p.input-message {
  display: inline;
  color: #e6708e;
  font-size: 8px;
}

p.input-message-test {
  display: inline;
  color: #e6708e;
  font-size: 8px;
}

label {
  font-size: 17px;
  
}

input {
  display: block;
  /* height: 35px;
  width: 250px; */
  height: 40%;
  width: 90%;
  font-size: 17px;
  font-family: inherit; 
  color: grey;
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  border-color: grey;
  padding: 2px 5px 2px 5px;
}

input:focus, #message-div > textarea:focus {
  outline: none;
}

#contact-text {
  font-size: 20px;
  font-weight: 200;
  margin-right: 100px;
  color: grey;
  text-align: center;
  margin-bottom: 30px;
  grid-column-start: first;
}

#message-div > textarea {
  width: 95%;
  height: 100px;
  border-color: grey;
  border-radius: 9px;
  border-width: 1px;
  font-size: 17px;
  font-family: inherit; 
  color: grey;
  padding: 2px 5px 2px 5px;
}

.form > div > label::after {
  content: "*";
  color: #e6708e;
  padding-left: 0.2em;
}
#first-name-div {
  grid-column-start: first;
  grid-column-end: second; 
  grid-row-start: first;
  grid-row-end: first;
}

#last-name-div {
  grid-column-start: second;
  grid-column-end: end; 
  grid-row-start: first;
  grid-row-end: first;
  
}

#email-div {
  grid-column-start: first;
  grid-column-end: second;
  grid-row-start: second;
  grid-row-end: second;
}



#phone-number-div {
  grid-column-start: second;
  grid-column-end: end; 
  grid-row-start: second;
  grid-row-end: second;
}

#subject-div {
  grid-column-start: first;
  grid-column-end: end;
  grid-row-start: third;
  grid-row-end: third;
}

#subject-div > input[type=text] {
  width: 95%;
}

#message-div {
  grid-column-start: first;
  grid-column-end: end;
  grid-row-start: fourth;
  grid-row-end: fourth;
}

#submit {
  grid-column-start: first;
  grid-column-end: end;
  width: 40%;
  height: 70%;
  margin-right: 32%;
  margin-left: auto;
  grid-row-start: fifth;
  grid-row-end: fifth;
  border-radius: 10px;
  cursor: pointer;
}
/* maps styles*/

#map-div {
  height: 400px;
  width: inherit;
  z-index: 1;
  margin-left: 20px;
  margin-right: 50px;
  margin-bottom: 20px;
  grid-column-start: first;
}

#map {
  height: inherit;
  z-index: 1;
}

#marker-label {
  background-color: white;
  border-radius: 8px;
  height: 60px;
  width: 220px;
  text-align: center;
  font-size: medium;
  padding-top: 10px;
  padding-bottom: 5px;
  margin-top: 0;
  background-color:  #4eacd4;
}
#jordan-address {
  font-family: 'Avenir-Light' sans-serif;
  color: white;
}
#address {
  font-family: 'Avenir-Light' sans-serif;
  color: white;
  font-size: 12px;
  margin-top: 10px;
}
#address-2 {
  font-family: 'Avenir-Light' sans-serif;
  color: white;
  font-size: 12px;
}


/*Footer Styles*/

#footer {
  height: 300px;
  background-color: lightgray;
  display: grid;
  grid-template-columns: [first] 33% [second] 10% [third] 30% [fourth] 20% [end];
  padding-left: 70px;
  padding-top: 50px;
}

#footer-logo-div {
  padding-top: 20px;
}
#footer-logo {
  width: 250px;
}

#footer-menu {
  grid-column-start: second;
  grid-column-end: second;
  color: #353536;
}

#menu-title {
  font-size: 20px;
  font-weight: 600;
}


#footer-contact {
  color: #353536
}
#footer-contact > li:nth-child(1) {
  font-size: 20px;
  font-weight: 600;
}

.footer ul li {
  list-style-type: none;
}

.footer ul li a {
  text-decoration: none;
  color: #413E3D;
}
#social-media {
  display: grid;
  grid-template-columns: [first] 30% [second] 70% [end];
  grid-template-rows: [first] 20% [second] 20% [end];
  grid-column-start: fourth;
  padding-top: 20px;
}
#instagram-svg {
  width: 40px;
  fill: #353536;
}

#tiktok-svg {
  width: 40px;
  fill: #353536;
}


/* Home Page Responsive Styles */
@media screen and (max-width: 1300px) and (min-width: 1101px) {
  #menu {
    grid-template-columns: [first] 25% [second] 25% [third] 25% [fourth] 25% [end];
  }
  #menu > li > a {
    font-size: 20px;
  }
  h1{
    font-size: 90px;
  }
  .chiropractic {
    font-size: 40px;
  }
  #jordan-quote {
    font-size: 17px;
  }

  #headers {
    padding-left: 50px;
  }

  /* Contact Styles */
  /* #message-div > textarea {
    width: 250px;
  } */
  
}

@media screen and (max-width: 1100px) {
  /* Menu Styles */
  #menu {
    grid-template-columns: [first] 25% [second] 25% [third] 25% [fourth] 25% [end];
  }
  #menu > li > a {
    font-size: 20px;
  }
  /* Main Display Styles */
  #home {
    grid-template-columns: [first] 25% [second] 25% [third] 25% [fourth] 25% [end];
  }
  #jordan-div {
    display: none;
  }
  #headers {
    grid-column-start: second;
    grid-column-end: end;
    padding-right: 150px
  }
  h1{
    font-size: 86px;
  }
  .chiropractic {
    font-size: 40px;
  }
  #jordan-quote {
    font-size: 17px;
  }
  
  #treatment-spiel {
    margin-left: 100px;
    margin-right: 100px;
  }

  /* Services Styles */
  #chiropractic-text {
    font-size: 15px;
  }

  #chiropractic-services {
     padding-right: 20px;
  }

  h6 {
    margin-left: 150px;
  }
  #estim-text {
    font-size: 15px;
    margin-left: 150px;
    margin-right: 150px;
  }

  #estim-img {
    width: 500px;
  }

  h7 {
    margin-left: 30px;
  }
  #cupping-text {
    font-size: 15px;
    margin-left: 30px;
  }

  /* Footer Styles */
  #footer-logo {
    width: 200px;
  }
  #footer-menu {
    padding-left: 0;
  }
  #footer-contact {
    margin-right: 10px;
  }
  
}


@media screen and (max-width: 860px) {
  #menu {
    display: none;
  }

  #menu-icon-div {
    grid-column-start: fourth;
    padding-top: 20px;
  }

  #menu-icon {
    display: block;
    width: 50px;
    height: 50px;
    fill: grey;
    
  }

  nav {
    grid-template-columns: [first] 33% [second] 26% [third] 30% [fourth] 10% [end];
  }

  #headers {
    grid-column-start: first;
    grid-column-end: end;
    padding-right: 50px;
    padding-left: 50px;
  }

  #treatment-spiel {
    margin-left: 50px;
    margin-right: 50px;
  }
  /* Contact Styles */
  #map-div {
    height: 350px;
    margin-right: 20px;
  }

  #marker-label {
    max-width: 230px !important;
  }
  #contact-div {
    grid-template-rows: [first] 13% [second] 40% [third] 45%[end];
    grid-template-columns: [first] 100% [second] 0% [end];
    height: 1000px;
  }
  .form {
    grid-template-columns: [first] 50% [second] 50% [end];
    grid-template-rows: [first] 1fr [second] 1fr [third] 1fr [fourth] 2fr [fifth] 1fr [end];
    grid-row-start: third;
    grid-row-end: third;
    grid-column-start: first;
    margin-left: 20px;
    margin-top: 20px;
    
  }

  #submit {
    margin-top: 20px;
  }
  #contact-text {
    display: none;
  }
  /* About Styles */
  #about-content-div {
    grid-template-columns: [first] 1fr [second] 1fr [end];
    grid-template-rows: [first] 10% [second] 600px [third] .3fr [fourth] 1fr [fifth] 1fr [end]
  }

  #about-dr-jordan {
    display: block;
    grid-column-start: first;
    grid-column-end: end;
    grid-row-start: first;
    color: grey;
    font-size: 35px;
    margin-left: 45px;
    margin-top: 45px;

  }

  #portrait-2 {
    grid-column-start: first;
    grid-column-end: end;
    grid-row-start: second;
    grid-row-end: third;
    margin-right: auto;
    margin-left: auto;
    width: 95%;
    max-width: 400px;
  }

  #intro {
    grid-row-start: fourth;
    grid-column-start: first;
    grid-column-end: end;
  }

  #biography {
    grid-column-start: first;
    grid-column-end: end;
    grid-row-start: third;
    grid-row-end: fourth;
  }

  #chiro-care-quote {
    grid-row-start: fifth;
    grid-row-end: end;
    grid-column-start: first;
    grid-column-end: end;
  }
  


  /* Services Styles */

  h4 {
    font-size: 30px;
  }
  #chiropractic-services {
    grid-template-rows: [first] min-content [second] 8% [third] 1fr [end];
    grid-template-columns: [first] 50% [second] 50% [end];
    height: auto;
    padding: 0;
  }

  #adjustment-img {
    grid-column-start: first;
    grid-column-end: end;
    width: 90%;
    margin-right: auto;
    margin-left: auto;
  }

  h5 {
    grid-column-start: first;
    grid-column-end: end;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 0;
  }

  #chiropractic-text {
    grid-column-start: first;
    grid-column-end: end;
    margin-top: 10px;
    margin-right: 30px;
    margin-left: 30px;
  }

  #e-stim-services {
    display: grid;
    grid-template-rows: [first] min-content [second] 8% [third] 1fr [end];
    height: auto;
    padding: 0;
  }

  #estim-img {
    grid-row-start: first;
    grid-row-end: first;
    grid-column-start: first;
    grid-column-end: end;
    width: 90%;
    margin-right: auto;
    margin-left: auto;
  }

  h6 {
    grid-column-start: first;
    grid-column-end: end;
    grid-row-start: second;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 0;
    margin-left: 0;
  }

  #estim-text {
    grid-column-start: first;
    grid-column-end: end;
    grid-row-start: third;
    margin-top: 10px;
    margin-right: 30px;
    margin-left: 30px;
  }

  #cupping-services {
    display: grid;
    grid-template-rows: [first] min-content [second] 8% [third] 1fr [end];
    grid-template-columns: [first] 50% [second] 50% [end];
    height: auto;
    padding: 0;
    margin: 0;
    margin-bottom: 30px;
  }

  #cupping-img {
    grid-row-start: first;
    grid-row-end: first;
    grid-column-start: first;
    grid-column-end: end;
    width: 90%;
    margin-right: auto;
    margin-left: auto;
  }

  h7 {
    grid-column-start: first;
    grid-column-end: end;
    grid-row-start: second;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 0;
    margin-left: 0;
  }

  #cupping-text {
    grid-column-start: first;
    grid-column-end: end;
    grid-row-start: third;
    margin-top: 10px;
    margin-right: 30px;
    margin-left: 30px;
    margin-bottom: 25px;
  }
  
  /* Footer Styles */
  #footer {
    padding-left: 10px;
  }

  #footer-logo {
    width: 150px;
    margin-top: 15px;
  }
  
  #footer-contact {
    margin-right: 10px;
  }
  
  #instagram-svg-div {
    margin-left: 0;
  }
  #tiktok-svg-div {
    grid-row-start: second;
    margin-left: 0;
  }
}

@media screen and (max-width: 700px) {
  #footer {
    grid-template-rows: [first] 25% [second] 25% [third] 25% [fourth] 25% [end];
    grid-template-columns: [first] 50% [second] 50% [end];
    padding: 0;
    padding-left: 20px;
    height: 600px;
  }

  #footer-menu {
    margin: 0;
    grid-row-start: second;
    grid-column-start: first;
  }

  #footer-contact {
    margin: 0;
    padding: 0;
    grid-row-start: third;
    grid-column-start: first;
  }

  #social-media {
    padding: 0;
    grid-row-start: fourth;
    grid-column-start: first;
    grid-template-columns: [first] 20% [second] 70% [end];
  }
  #tiktok-svg-div {
    grid-row-start: first;
    grid-column-start: second;
  }
}

@media screen and (max-width: 600px) {
  #home {
    height: 650px;
  }
  #headers {
    grid-template-rows: [first] 17% [second] 15% [third] 20% [fourth] 40% [end];
    padding-top: 50px;
    padding-right: 20px;
    padding-left: 20px;
  }
  h1{
    font-size: 58px;
    margin: 0;
  }
  .chiropractic {
    font-size: 36px;
  }
  #jordan-quote {
    font-size: 14px;
    font-weight: 600;
  }
  #main-logo {
    width: 120px;
  }
  #menu-icon {
    width: 35px;
    height: 35px;
  }

  #menu-icon-div {
    padding-top: 10px;
  }

  #services-homepage {
    grid-template-rows: [first] 27% [second] 30% [third] 42% [end];
  }
  #treatment-spiel {
    margin-left: 25px;
    margin-right: 25px;
    font-size: 15px;
  }
  #chiropractic {
    font-size: 18px;
  }
  
  #e-stim {
    font-size: 18px;
  }
  
  #cupping {
    font-size: 18px;
  }
  #chiropractic-svg {
    height: 80px;
    margin-top: 20px;
  }
  #estim-svg {
    height: 80px;
    margin-top: 20px;
  }
  #cupping-svg {
    height: 80px;
    margin-top: 20px;
  }

  /* About Styles */
  #about-content-div {
    grid-template-columns: [first] 1fr [second] 1fr [end];
    grid-template-rows: [first] 10% [second] 600px [third] .3fr [fourth] 1fr [fifth] 1fr [end];
  }

  #about-dr-jordan {
    display: block;
    grid-column-start: first;
    grid-column-end: end;
    grid-row-start: first;
    color: grey;
    font-size: 35px;
    margin-left: 45px;
    margin-top: 45px;

  }

  #portrait-2 {
    grid-column-start: first;
    grid-column-end: end;
    grid-row-start: second;
    grid-row-end: third;
    margin-right: auto;
    margin-left: auto;
    margin-top: -35px;
    width: 95%;
    max-width: 400px;
  }

  /* Services Styles */
  #adjustment-img, #estim-img, #cupping-img {
    width: 100%;
  }
}

@media screen and (max-width: 500px) {
  /* Footer Styles */
  #tiktok-svg-div {
    margin-left: 10px;
  }

  /* About Styles */
  #about-content-div {
    grid-template-rows: [first] 9% [second] 1fr [third] 100px [fourth] 1fr [fifth] .8fr [end];
  }

  #about-dr-jordan {
    margin-left: 10px;
    font-size: 30px;
  }

  #portrait-2 {
    margin-top: -55px;
    width: 100%;
    max-width: 500px;
    min-width: 400px;
  }
  #biography {
    margin-top: 20px;
  }
  #intro {
    margin-top: 0;
  }
}

@media screen and (max-width: 400px) {
  .form {
    margin: 0 20px 0 20px;
    grid-template-columns: [first] 100% [second] 0% [end];
    grid-template-rows: [first] 1fr [second] 1fr [third] 1fr [fourth] 1fr [fifth] 1fr [sixth] 2fr [seventh] 1fr [end];
  }

  input {
     width: 95%;
  }
  #first-name-div {
    grid-row-start: first;
    grid-row-end: first;
    grid-column-start: first;
  }
  #last-name-div {
    grid-row-start: second;
    grid-row-end: second;
    grid-column-start: first;
    grid-column-end: first;
  }
  #email-div {
    grid-row-start: third;
    grid-row-end: third;
    grid-column-start: first;
    grid-column-end: first;
  }
  #phone-number-div {
    grid-row-start: fourth;
    grid-row-end: fourth;
    grid-column-start: first;
    grid-column-end: first;

  }
  #subject-div {
    grid-row-start: fifth;
    grid-row-end: fifth;
    grid-column-start: first;
    grid-column-end: first;
  }
  #message-div {
    grid-row-start: sixth;
    grid-row-end: sixth;
    grid-column-start: first;
    grid-column-end: first;
  }

  #submit {
    grid-row-start: seventh;
    grid-row-end: seventh;
    grid-column-start: first;
    grid-column-end: first;
    height: 90%;
    border-radius: 15px;
    margin-top: 5px;
  }
  
  /* About Styles */

  #about-content-div {
    grid-template-rows: [first] 10% [second] .8fr [third] 100px [fourth] 1fr [fifth] .6fr [end];
  }
  #portrait-2 {
    margin-top: -55px;
    width: 100%;
    max-width: 500px;
    min-width: 350px;
  }

  #biography {
    margin-left: 20px;
  }
  #intro {
    margin-left: 20px;
    font-size: 15px;
  }

  #chiro-care-quote {
    font-size: 20px;
    margin-left: 10px;
    margin-right: 48px;
    margin-bottom: 0;
  }
}

@media screen and (max-width: 380px) {
  #home {
    height: 500px;
  }

  nav {
    padding-left: 10px;
    padding-bottom: 8px;
  }
  #main-logo {
    width: 90px;
  }
  #menu-icon-div {
    padding-top: 2px;
  }
  #headers {
    padding-right: 12px;
    padding-left: 12px;
  }

  h1{
    font-size: 48px;
    margin: 0;
  }
  .chiropractic {
    font-size: 30px;
  }

  #jordan-quote {
    font-size: 12px;
    
  }

  #services-homepage {
    grid-template-rows: [first] 20% [second] 30% [third] 50% [end];
  }
  #treatment-spiel {
    margin-left: 23px;
    margin-right: 23px;
    font-size: 14px;
  }

  #chiropractic-svg {
    height: 70px;
    margin-top: 20px;
  }
  #estim-svg {
    height: 70px;
    margin-top: 20px;
  }
  #cupping-svg {
    height: 70px;
    margin-top: 20px;
  }

  #social-media {
    margin-top: 20px;
    grid-template-columns: [first] 30% [second] 70% [end];
  }

  #tiktok-svg-div {
    margin-left: 10px;
  }
}
